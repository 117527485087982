body {
  margin: 0 !important;
  padding: 0 !important;
}

/* Adiciona a fonte Anton-Regular com peso 400 */
@font-face {
  font-family: 'Anton';
  src: url('../assets/fonts/Anton-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

/* Adiciona a fonte PublicSans-VariableFont_wght com pesos 300-700 */
@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/PublicSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/PublicSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/PublicSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/PublicSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/PublicSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e8e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  height: 6rem;
  border-radius: 4px;
  background: rgb(0, 163, 16);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 103, 16);
}

.swiper-pagination {
  position: static;
  margin-top: 1rem;
}

.swiper-wrapper {
  max-width: 90vw
}